import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTablesComp from "components/dataTable";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes/constant";
import moment from "moment";
import { updateSlaType } from "redux/asyncApi/uploadApi";
import { dateFormat, removeUnderScore } from "util/helpers";
import DataTablev2 from "components/dataTablev2";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue, SelectLabel } from "components/SelectV2";
import { DeliveryTypesTable } from "../../../../../../src/Custom";

const SlaTable = ({ data, success, sla_types }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    loading,
    userAuth: { token },
  } = useSelector((state) => state.app) || {};
  const { selectedUser } = useSelector((state) => state.upload);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // const cellRendrer = useCallback(
  //   (cell, row, column, index, tHeadId) => {
  //     if (column === "original_file_name") {
  //       return (
  //         <div id={row.id} className="file_name_head">
  //           {row?.original_file_name}
  //         </div>
  //       );
  //     }

  //     if (column === "file_type") {
  //       return (
  //         <div className="uppercase" id={row.id}>
  //           {removeUnderScore(row?.file_type ?? "")}
  //         </div>
  //       );
  //     }
  //     if (column === "file_page_count") {
  //       return (
  //         <div id={row.id}>
  //           <span className="role">{row?.file_page_count}</span>
  //         </div>
  //       );
  //     }

  //     if (column === "file_deliver_date") {
  //       return (
  //         <div id={row.id}>
  //           <span className="role">
  //             {moment(row?.file_deliver_date).format(dateFormat)}
  //           </span>
  //         </div>
  //       );
  //     }
  //     if (column === "sla_types") {
  //       const onSelectChangeHandler = (e) => {
  //         const { value } = e.target;
  //         const values = {
  //           id: row.id,
  //           sla_type: value,
  //           // order_temp_id: row.order_temp_id,
  //           user_id: selectedUser?.value,
  //         };
  //         dispatch(
  //           updateSlaType({
  //             token,
  //             values,
  //           })
  //         );
  //       };
  //       return (
  //         <div id={row.id}>
  //           <span className="role">
  //             <select
  //               id="req_file"
  //               aria-labelledby={` sla_types req_file`}
  //               className="form-select"
  //               value={row.sla_type}
  //               onChange={onSelectChangeHandler}
  //             >
  //               {row?.sla_types.map((type, index) => (
  //                 <option
  //                   key={type.id}
  //                   value={type?.feature_name?.toLowerCase()}
  //                 >
  //                   {`${type.feature_name} - USD ${type.feature_custom_price}`}
  //                 </option>
  //               ))}
  //             </select>
  //           </span>
  //         </div>
  //       );
  //     }

  //     return row[column];
  //   },
  //   [data]
  // );

  // const tableHead = [
  //   {
  //     key: "original_file_name",
  //     title: "FILE NAME",
  //     sorting: true,
  //     searching: true,
  //     width: "40%",
  //     theadId: "original_file_name",
  //   },
  //   {
  //     key: "file_type",
  //     title: "FILE TYPE",
  //     sorting: true,
  //     searching: true,
  //     // width: "40%",
  //     theadId: "file_type",
  //   },
  //   {
  //     key: "file_page_count",
  //     title: "PAGES",
  //     sorting: true,
  //     searching: false,
  //     // width: "20%",
  //     theadId: "file_page_count",
  //   },

  //   {
  //     key: "file_deliver_date",
  //     title: "Est. Date of Delivery",
  //     sorting: true,
  //     searching: false,
  //     // width: "20%",
  //     theadId: "file_deliver_date",
  //   },
  //   {
  //     key: "sla_types",
  //     title: "Delivery options",
  //     sorting: true,
  //     searching: false,
  //     // width: "20%",
  //     theadId: "sla_types",
  //   },
  // ];

  const columns = [
    {
      id: "original_file_name",
      accessorKey: "original_file_name",
      header: "File Name",
      cell: ({ row }) => {
        return (
          <div id={row?.original?.id} className="file_name_head">
            {row?.original?.original_file_name}
          </div>
        );
      }
    },
    {
      id: "file_type",
      accessorKey: "file_type",
      header: "File Type",
      cell: ({ row }) => {
        return (
          <div id={row?.original?.id} className="file_name_head uppercase">
            {removeUnderScore(row?.original?.file_type)}
          </div>
        );
      }
    },
    {
      id: "file_page_count",
      accessorKey: "file_page_count",
      header: "Pages",
      cell: ({ row }) => {
        return (
          <div id={row?.original?.id} className="file_name_head">
            {row?.original?.file_page_count}
          </div>
        );
      }
    },
    {
      id: "file_deliver_date",
      accessorKey: "file_deliver_date",
      header: "Est. Date of Delivery",
      cell: ({ row }) => {
        return (
          <div id={row?.original?.id} className="file_name_head">
            {moment(row?.original?.file_deliver_date).format(dateFormat)}
          </div>
        );
      }
    },
    {
      id: "sla_types",
      accessorKey: "sla_types",
      header: "Delivery Options",
      cell: ({ row }) => {
        const onSelectChangeHandler = (e) => {
          const { value } = e.target;
          const values = {
            id: row?.original?.id,
            sla_type: value,
            // order_temp_id: row.order_temp_id,
            user_id: selectedUser?.value,
          };
          dispatch(
            updateSlaType({
              token,
              values,
            })
          );
          setTimeout(() => {
            const selectElement = document.getElementById("select_" + reqFileId);
            if (selectElement) {
              selectElement.focus();
            }
          }, 500);
        };

        const reqFileId = "req_file" + row?.original?.id;
        return (
          <div id={row?.original?.id}>
            <span className="role">
              {/* <select
                id="req_file"
                aria-labelledby={`sla_types req_file`}
                className="form-select"
                value={row?.original?.sla_type}
                onChange={onSelectChangeHandler}
              >
                {row?.original?.sla_types.map((type, index) => (
                  <option
                    key={type.id}
                    value={type?.feature_name?.toLowerCase()}
                  >
                    {`${type.feature_name} - USD ${type.feature_custom_price}`}
                  </option>
                ))}
              </select> */}
              <Select
                onValueChange={
                  (value) => {
                    onSelectChangeHandler({ target: { value } })
                  }}

                defaultValue={row?.original?.sla_type}
              >
                <SelectTrigger id={"select_" + reqFileId}>
                  <SelectValue id="req_file" aria-labelledby={`sla_types req_file`}
                  >
                    {
                      row?.original?.sla_types.find((type) => type?.feature_name?.toLowerCase() === row?.original?.sla_type)?.feature_name
                    } - USD {
                      row?.original?.sla_types.find((type) => type?.feature_name?.toLowerCase() === row?.original?.sla_type)?.feature_custom_price
                    } per page
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  {row?.original?.sla_types.map((type, index) => (
                    <SelectItem key={type.id}  value={type?.feature_name?.toLowerCase()}
                    >
                      {type.feature_name} - USD {type.feature_custom_price} per page
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </span>
          </div>
        );
      }
    }

  ]
  useEffect(() => {
    DeliveryTypesTable();
  }, []);

  return (
    <>
      <div className="dt-responsive border-table">
        {/* {!loading && success && data?.length > 0 && ( */}
        {/* <DataTablesComp
            tableHead={tableHead}
            data={data}
            renderCell={cellRendrer}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
            type="order"
            search={false}
            pageSizing={true}
            paginationBar={true}
            // pageSize={true}
            tableDescription="S.L.A List"
          /> */}

        <DataTablev2
          data={data ? data : []}
          totalRecords={data?.length}
          columns={columns}
          search={false}
          title={"Delivery Types"}
          tablePagination={false}
        />
        {/*  )}
         {(!loading && !success) ||
          (data?.length <= 0 && (
            <div className="no-record-main">
            <div className="no-record">No Record Found</div>
             </div>
          ))} */}
      </div>
    </>
  );
};
export default SlaTable;
