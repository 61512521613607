import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { currencyFormatter, removeUnderScore } from "util/helpers";
import { OPTIONAL_SERVICES_COST_PER_PAGE } from "constants/additionalServices";

const OptionalServicesTable = ({order_details, data, pageNumber, perPageData}) => {
    // Create styles
  const tableStyles = StyleSheet.create({
    table: {
      display: "table",
      marginBottom: "20px",
      width: "95%",
      height: "40%",
      borderBottom: "1px solid black",
      overflow: "hidden",
      marginLeft: "auto",
      marginRight: "auto",
    },
    colSize5: {
      width: "6%",
    },
    colSize10: {
      minWidth: "10%",
    },
    colSize70: {
      minWidth: "70%",
    },
    border: {
      border: "1px solid black",
    },
    textCenter: {
      textAlign: "center",
    },
    textLeft: {
      textAlign: "left",
    },
    marginTop: {
      marginTop: "20px",
    },
    marginBottom: {
      marginTop: "20px",
    },
    marginLeftRight: {
      marginLeft: "20px",
      marginRight: "20px",
    },
    textUpperCase: {
      textTransform: "uppercase",
    },
  });
  const tHeadStyles = StyleSheet.create({
    tHead: {
      margin: "0px",
      flexDirection: "row",
    },
    tHeadCol: {
      display: "flex",
      width: "11%",
      alignItems: "center",
      backgroundColor: "#cc6f26",
      padding: "5px",
      color: "#fff",
    },
    tHeadCell: {
      margin: "auto",
      marginTop: 0,
      fontSize: 10,
      textAlign: "center",
      width: "100%",
      fontWeight: "bold",
      color: "#fff",
      verticalAlign: "middle",
    },
  });
  const tRowStyles = StyleSheet.create({
    tRow: {
      margin: "0px",
      flexDirection: "row",
      marginBottom: "8px",
    },
    tRowCol: {
      display: "flex",
      width: "11%",
      height: "100%",
      padding: "5px",
      alignItems: "center",
      backgroundColor: "#eee",
    },
    tRowCell: {
      margin: "auto",
      marginTop: 0,
      fontSize: 10,
      textAlign: "left",
      width: "100%",
      verticalAlign: "middle",
    },
  });

  const {
    colSize70,
    textCenter,
    textLeft,
    marginTop,
  } = tableStyles;
  const { tHead, tHeadCol, tHeadCell } = tHeadStyles;
  const { tRow, tRowCol, tRowCell } = tRowStyles;

  const tableHead = ["Cost per Page", "Pages", "Cost"];
  const { acc_extend_alt: extentedAlt, acc_tabel_summary: tableSummary } = order_details

  let filePageCount = 0;
  if(data) {
    data.forEach(file => {
      const { file_page_count } = file;
      filePageCount += file_page_count;
    })
  }

  return (
    <View style={[marginTop]}>
        <View style={[tHead]}>
            <View style={[tHeadCol, colSize70]}>
                <Text style={[tHeadCell, textLeft]}>Optional Service</Text>
            </View>
            {tableHead.map((item, index) => (
            <View key={index} style={[tHeadCol]}>
                <Text style={[tHeadCell]}>{item}</Text>
            </View>
            ))}
        </View>

        {extentedAlt === 'Y' && (
            <View style={[tRow]}> 
                <View style={[tRowCol, colSize70]}>
                    <Text style={[tRowCell, textLeft]}>Extended Alt</Text>
                </View>
                <View style={[tRowCol]}>
                    <Text style={[tRowCell, textCenter]}>${OPTIONAL_SERVICES_COST_PER_PAGE}</Text>
                </View>
                <View style={[tRowCol]}>
                    <Text style={[tRowCell, textCenter]}>{filePageCount}</Text>
                </View>
                <View style={[tRowCol]}>
                    <Text style={[tRowCell, textCenter]}>${Number(filePageCount * OPTIONAL_SERVICES_COST_PER_PAGE).toFixed(2)}</Text>
                </View>
            </View>
        )}

        {tableSummary === 'Y' && (
            <View style={[tRow]}> 
                <View style={[tRowCol, colSize70]}>
                    <Text style={[tRowCell, textLeft]}>Table Summary</Text>
                </View>
                <View style={[tRowCol]}>
                    <Text style={[tRowCell, textCenter]}>${OPTIONAL_SERVICES_COST_PER_PAGE}</Text>
                </View>
                <View style={[tRowCol]}>
                    <Text style={[tRowCell, textCenter]}>{filePageCount}</Text>
                </View>
                <View style={[tRowCol]}>
                    <Text style={[tRowCell, textCenter]}>${Number(filePageCount * OPTIONAL_SERVICES_COST_PER_PAGE).toFixed(2)}</Text>
                </View>
            </View>
        )}

    </View>
  )
}

export default OptionalServicesTable;