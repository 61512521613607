import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ArrowDownLong, InfoIcon } from "assets/images";
import {
  downloadAllPdf,
  downloadAllReport,
  downloadReport,
  download_pdf,
} from "redux/asyncApi/uploadApi";
import { Button } from "components/Buttonv2";
import { ORDER_STATUS_CLASS } from "constants/job_status";
import { getItem, setItem } from "constants/localstorage";
import {
  bytesToSize,
  currencyFormatter,
  dateFormat,
  noRecordFound,
  removeUnderScore,
} from "util/helpers";
import CustomTooltip from "components/CustomTootip";
import DataTablev2 from "components/dataTablev2";
import { useEffect } from "react";
import { handleOrderInformationTable } from "../../../../src/Custom";
import { useLocation } from "react-router-dom";

const OrderDetailsTable = ({
  order_details,
  success,
  loading,
  file_exist,
  id,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const location = useLocation();
  const [prevPerPage, setPrevPerPage] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const perPageValue = urlParams.get("per_page");
    if (prevPerPage !== perPageValue) {
      handleOrderInformationTable();
      setPrevPerPage(perPageValue);
    }
  }, [location.search, prevPerPage]);

  //Redux state
  const {
    userAuth: { token },
  } = useSelector((state) => state.app);

  //Redux action dispatcher.
  const dispatch = useDispatch();

  //Static varriable for compare file status.
  const complete = "complete";

  //Methods
  //This function handle datatable row and columns.
  // const cellRendrer = useCallback(
  //   (cell, row, column, index) => {
  //     if (column === "file_name") {
  //       return (
  //         <div className="flex">
  //           <span id={row.id} className="file_name_head">
  //             {row?.original_file_name ?? ""}
  //           </span>
  //           {row?.comments && (
  //             <CustomTooltip
  //               heading="Message"
  //               content={row?.comments}
  //               Icon={InfoIcon}
  //               tooltipId="message-tooltip"
  //               contentclass="tip-content"
  //               iconClass="tooltip_icon"
  //               mainClass="custom_tooltip"
  //             />
  //           )}
  //         </div>
  //       );
  //     }

  //     if (column === "file_type") {
  //       return (
  //         <div id={cell + index} className="uppercase">
  //           {removeUnderScore(row?.file_type ?? "")}
  //         </div>
  //       );
  //     }
  //     if (column === "file_deliver_date") {
  //       return (
  //         <div>
  //           <span className="role">
  //             {moment(row.file_deliver_date).format(dateFormat)}
  //           </span>
  //         </div>
  //       );
  //     }
  //     if (column === "file_status") {
  //       return (
  //         <div>
  //           <span className={`_badge ${ORDER_STATUS_CLASS[row?.label_value]}`}>
  //             {row?.label_text}
  //           </span>
  //         </div>
  //       );
  //     }
  //     if (column === "file_price_per_page") {
  //       return (
  //         <div>
  //           <span>{currencyFormatter(row?.file_price_per_page)}</span>
  //         </div>
  //       );
  //     }
  //     if (column === "file_page_count") {
  //       return <div>{row.file_page_count}</div>;
  //     }
  //     if (column === "file_size") {
  //       return <div>{bytesToSize(row.file_size)}</div>;
  //     }
  //     if (column === "file_estimate_cost") {
  //       return (
  //         <div>
  //           <span>{currencyFormatter(row?.file_estimate_cost)}</span>
  //         </div>
  //       );
  //     }
  //     if (column === "id") {
  //       return (
  //         <>
  //           {row.file_status === complete && (
  //             <div className="download-flie-btns">
  //               <button
  //                 role={"link"}
  //                 autoFocus={getItem("id") === row.id ? true : false}
  //                 id={index + "1"}
  //                 className={`download-link job_button`}
  //                 onClick={() => {
  //                   setItem("id", row.id);
  //                   const values = {
  //                     id: row.id,
  //                     fileName: row.original_file_name,
  //                   };
  //                   dispatch(
  //                     download_pdf({
  //                       token,
  //                       values,
  //                     })
  //                   );
  //                 }}
  //                 download
  //                 aria-labelledby={
  //                   row.id +
  //                   " " +
  //                   row.job_document_type +
  //                   index +
  //                   " " +
  //                   index +
  //                   1
  //                 }
  //               >
  //                 <ArrowDownLong aria-hidden="true" focusable="false" />
  //                 Accessible File
  //               </button>
  //               <button
  //                 role={"link"}
  //                 autoFocus={getItem("id") === row.job_filename ? true : false}
  //                 id={index + "2"}
  //                 className={`download-link job_button`}
  //                 onClick={() => {
  //                   setItem("id", row.job_filename);
  //                   const values = {
  //                     id: row.id,
  //                     fileName: row.original_file_name,
  //                   };
  //                   dispatch(
  //                     downloadReport({
  //                       token,
  //                       values,
  //                     })
  //                   );
  //                 }}
  //                 download
  //                 aria-labelledby={
  //                   row.id +
  //                   " " +
  //                   row.job_document_type +
  //                   index +
  //                   " " +
  //                   index +
  //                   2
  //                 }
  //               >
  //                 <ArrowDownLong aria-hidden="true" focusable="false" /> Report
  //               </button>
  //             </div>
  //           )}
  //         </>
  //       );
  //     }

  //     return row[column];
  //   },
  //   [order_details]
  // );

  // //This array is work for show table headers dynamicly in data table.
  // const tableHead = [
  //   { title: "File Name", key: "file_name", sorting: true },
  //   { title: "Type", key: "file_type", sorting: true },
  //   { title: "Date of delivery", key: "file_deliver_date", sorting: true },
  //   { title: "status", key: "file_status", sorting: true },
  //   {
  //     title: "price per page",
  //     key: "file_price_per_page",
  //     sorting: true,
  //   },
  //   { title: "no of pages", key: "file_page_count", sorting: true },
  //   { title: "file size", key: "file_size", sorting: false },
  //   { title: "total", key: "file_estimate_cost", sorting: false },
  //   { title: "Download", key: "id", sorting: false },
  // ];

  const columns = [
    {
      header: "File Name",
      accessorKey: "file_name",
      size: 250,
      cell: ({ row }) => (
        <div className="flex">
          <div id={row.id} className="flex-wrap">
            <div id={row.id} className="whitespace-break-spaces">
              {row?.original?.original_file_name.length > 20 ? (
                <span className="break-words break-all font-bold" title={row?.original?.original_file_name}>{`${row?.original?.original_file_name}`}</span>
              ) : (
                <span className="font-bold">{row?.original?.original_file_name}</span>
              )}
            </div>
          </div>
          {row?.original?.comments && (
            <CustomTooltip
              heading="Message"
              content={row?.original?.comments}
              Icon={InfoIcon}
              tooltipId="message-tooltip"
              contentclass="tip-content"
              iconClass="tooltip_icon"
              mainClass="custom_tooltip"
            />
          )}
        </div>
      ),
    },
    {
      header: "Type",
      accessorKey: "file_type",
      cell: ({ row }) => (
        <div id={row?.original?.file_type + row.id} className="uppercase">
          {removeUnderScore(row?.original?.file_type ?? "")}
        </div>
      ),
    },
    {
      header: "Date of Delivery",
      accessorKey: "file_deliver_date",
      cell: ({ row }) => (
        <div>
          <span className="role">
            {moment(row?.original?.file_deliver_date).format(dateFormat)}
          </span>
        </div>
      ),
    },
    {
      header: "Status",
      accessorKey: "file_status",
      cell: ({ row }) => (
        <div>
          <span className={`_badge ${ORDER_STATUS_CLASS[row?.original?.label_value]}`}>
            {row?.original?.label_text}
          </span>
        </div>
      ),
    },
    {
      header: "Price per Page",
      accessorKey: "file_price_per_page",
      cell: ({ row }) => (
        <div>
          <span>{currencyFormatter(row?.original?.file_price_per_page)}</span>
        </div>
      ),
    },
    {
      header: "No of Pages",
      accessorKey: "file_page_count",
      cell: ({ row }) => <div>{row?.original?.file_page_count}</div>,
    },
    {
      header: "File Size",
      accessorKey: "file_size",
      cell: ({ row }) => <div>{bytesToSize(row?.original?.file_size)}</div>,
    },
    {
      header: "Total",
      accessorKey: "file_estimate_cost",
      cell: ({ row }) => (
        <div>
          <span>{currencyFormatter(row?.original?.file_estimate_cost)}</span>
        </div>
      ),
    },
    {
      header: "Download",
      accessorKey: "id",
      cell: ({ row }) => (
        <>
          {row?.original?.file_status === complete && (
            <div className="flex flex-col gap-1">
              <Button
                role={"link"}
                autoFocus={getItem("id") === row?.original?.id ? true : false}
                id={row?.original?.id + "1"}
                // className={`download-link job_button`}
                onClick={() => {
                  setItem("id", row?.original?.id);
                  const values = {
                    id: row?.original?.id,
                    fileName: row?.original?.original_file_name,
                  };
                  dispatch(
                    download_pdf({
                      token,
                      values,
                    })
                  );
                }
                }
                download
                aria-labelledby={
                  row?.original?.id +
                  " " +
                  row?.original?.job_document_type +
                  row?.original?.id +
                  " " +
                  row?.original?.id +
                  1
                }
              >
                <ArrowDownLong aria-hidden="true" focusable="false" className="mr-2" /> Accessible File
              </Button>
              {row?.original?.file_type === 'pdf' && (
                <Button
                  role={"link"}
                  autoFocus={getItem("id") === row?.original?.job_filename ? true : false}
                  id={row?.original?.id + "2"}
                  // className={`download-link job_button`}
                  onClick={() => {
                    setItem("id", row?.original?.job_filename);
                    const values = {
                      id: row?.original?.id,
                      fileName: row?.original?.original_file_name,
                    };
                    dispatch(
                      downloadReport({
                        token,
                        values,
                      })
                    );
                  }}
                  download
                  aria-labelledby={
                    row?.original?.id +
                    " " +
                    row?.original?.job_document_type +
                    row?.original?.id +
                    " " +
                    row?.original?.id +
                    2
                  }
                >
                  <ArrowDownLong aria-hidden="true" focusable="false" className="mr-2" /> Report
                </Button>
              )}
            </div>
          )}
        </>
      ),
    },

  ]

  useEffect(() => {
      handleOrderInformationTable();
  }, []);

  return (
    <div className="dt-responsive">
      {/* {success && (
        <div> */}
      {/* {!loading && success && ( */}
      {/* <DataTablesComp
              tableHead={tableHead}
              data={order_details}
              renderCell={cellRendrer}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              setPageSize={setPageSize}
              pageSize={pageSize}
              type="order-details"
              search={false}
              pageSizing={true}
              paginationBar={true}
              tableDescription="Order Details List"
            /> */}

      <DataTablev2
        data={order_details}
        totalRecords={order_details?.length}
        columns={columns}
        search={false}
        title={"Order Information"}
        searchableColumns={[
          {
            id: "file_name",
            title: "File Name",
          }, {
            id: "file_deliver_date",
            title: "Delivery Date",
          }
        ]}
      />
      {/* )} */}
      <div className="w-full flex flex-1 flex-row justify-end py-2 px-3">
        <Button
          variant="outline"
          disabled={!file_exist}
          title={"Download All Accessible Files"}
          onClick={() => {
            const values = {
              id: id,
            };
            dispatch(downloadAllPdf({ token, values }));
          }}
        // className={"button--border"}
        >
          Download All Accessible Files
        </Button>
        <Button
          disabled={!file_exist}
          title={"Download All Reports"}
          onClick={() => {
            const values = {
              id: id,
            };
            dispatch(downloadAllReport({ token, values }));
          }}
          className={"button--blue ms-3"}
        >
          Download All Reports
        </Button>
      </div>
      {/* </div> */}
      {/* )} */}
      {/* {!loading && !success && order_details?.message && (
        <div className="no-record-main">
          <div className="no-record">{noRecordFound}</div>
        </div>
      )} */}
    </div>
  );
};
export default OrderDetailsTable;
