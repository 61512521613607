import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { CreditIcons } from "assets/images";
import CardIcons from "components/stripeComponents/CardIcons";
import React from "react";
import PaymentSucceed from "./PaymentSucceed.js"

const StripeCardForm = ({
  formErrors,
  paymentError,
  paymentSuccess,
  iconCols,
  cardCols,
  expiryCols,
  cvcCols,
  amount,
  wallet_id,
}) => {

  //Destructring errors from formErrors prop
  const { cardNumberError, cardExpiryError, cardCvcError } = formErrors;

  //Card element style
  const style = {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#000000",
      },
    },
    invalid: {
      color: "#D40000",
      iconColor: "#D40000",
    },
  };

  return (
    <>
      {paymentSuccess ? (
        <PaymentSucceed wallet_id={wallet_id} amount={amount}/>
      ) : (
        <>
          <div className={iconCols}>
            <CardIcons
              mainClass="inputRow"
              cardsContainerClass="paymentMethods mt-3"
              imgClass="img-fluid"
              imgSrc={CreditIcons}
            />
          </div>
          {paymentError && (
            <div className="payment_error text-center">
              <ol role="alert" className="error_list">
                <li>Error: {paymentError}</li>
                {cardNumberError && <li>{cardNumberError}</li>}
                {cardExpiryError && <li>{cardExpiryError}</li>}
                {cardCvcError && <li>{cardCvcError}</li>}
              </ol>
            </div>
          )}
          {paymentSuccess && (
            <div className="text-success text-center">Payment successful!</div>
          )}
          <div className={cardCols}>
            <label className="payment_element_label inputRow">
              Card Number(e.g. 1234 1234 1234 1234):*
              <CardNumberElement
                options={{ style: style, showIcon: true, iconStyle: "default" }}
                className="payment_input_feild"
              />
            </label>
          </div>
          <div className={expiryCols}>
            <label className="payment_element_label inputRow">
              Card Expiry(MM/YY):*
              <CardExpiryElement
                options={{ style: style }}
                className="payment_input_feild"
              />
            </label>
          </div>
          <div className={cvcCols}>
            <label className="payment_element_label inputRow">
              Card CVC:*
              <CardCvcElement
                options={{ style: style }}
                className="payment_input_feild"
              />
            </label>
          </div>
        </>
      )}
    </>
  );
};

export default StripeCardForm;
