export function handleOrderInformationTable() {
    const timer = setTimeout(() => {
    const tables = document.querySelectorAll('table[aria-label="Order Information"]');
    tables.forEach(table => {
        const rows = table.querySelectorAll('tbody tr');
        rows.forEach(row => {
            const firstCell = row.querySelector('td');
            if (firstCell) {
                firstCell.setAttribute('role', 'rowheader');
            }
            const restCells = row.querySelectorAll('td:not(:first-child)');
            restCells.forEach(cell => {
                cell.setAttribute('role', 'cell');
            });
        });
    }); }, 1000);
}
 
export function DeliveryTypesTable() {
    const timer = setTimeout(() => {
        const deliveryTable = document.querySelector('table[aria-label="Delivery Types"]');
        const thElements = deliveryTable.querySelectorAll('thead th');
        thElements.forEach(th => {
            const buttonElement = th.querySelector('button');
            if (buttonElement) {
                const divElement = document.createElement('div');
                divElement.className = "items-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-9 px-4 py-2 button-wrapper flex flex-1 w-full justify-between";
                divElement.innerHTML = buttonElement.innerHTML;
                th.innerHTML = '';
                th.appendChild(divElement);
                th.removeAttribute('tabindex');
                th.removeAttribute('aria-sort');
                th.removeAttribute('aria-describedby');
            }
        });
    }, 1000);
}

export function handleUserListTable() {
    const timer = setTimeout(() => {
    const tables = document.querySelectorAll('table[aria-label="User List"]');
    tables.forEach(table => {
        const rows = table.querySelectorAll('tbody tr');
        rows.forEach(row => {
            const firstCell = row.querySelector('td');
            if (firstCell) {
                firstCell.setAttribute('role', 'rowheader');
            }
            const restCells = row.querySelectorAll('td:not(:first-child)');
            restCells.forEach(cell => {
                cell.setAttribute('role', 'cell');
            });
        });
    }); }, 1000);
}