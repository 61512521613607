import { CLIENT_USERS_TITLE } from "constants/title";
import React from "react";

import "./clientuser.scss";
import ClientUserTable from "./ClientUserTable";

const ClientUsers = () => {
  document.title = CLIENT_USERS_TITLE;
  return (
    <div className="mainWrapper clientUser">
      {/* <div className="mainTitleWrapper ">
        <div className="items-center">
          <div sm={6} md={6}>
            <h2 className="mainTitle mb-0" id="table_info">
              Client / Users
            </h2>
          </div>
        </div>
      </div> */}
      <div className="main-content">
        <ClientUserTable />
      </div>
    </div>
  );
};

export default ClientUsers;
