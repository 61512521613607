import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTablesComp from "components/dataTable";
import { orderListing } from "redux/asyncApi/orderApi";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROUTES } from "routes/constant";
import moment from "moment";
import { ORDER_STATUS_CLASS } from "constants/job_status";
import { dateFormat, notAvailable, usersTypes } from "util/helpers";
import DataTablev2 from "components/dataTablev2";
import { Checkbox } from "components/Checkboxv2";
const OrderTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { order_listing } = useSelector((state) => state.order) || {};
  const {
    loading,
    userAuth: {
      user: { id: userId },
    },
  } = useSelector((state) => state.app);
  const { message, status, order_list, total_records } = order_listing || {};
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [selectedRowIds, setSelectedRowIds] = React.useState([])

  const { token, user: userDetails } = useSelector(
    (state) => state.app.userAuth
  );

  const { admin, superAdmin, rootAdmin, postpaidRoot, postpaidAdmin } =
    usersTypes;


  const searchableColumns = [
    {
      id: "id",
      title: "Order Number",
    }, {
      id: "order_invoice_number",
      title: "Invoice Number",
    }, {
      id: "user",
      title: "Order By",
    }
  ];

  const location = useLocation();

  useEffect(() => {
    location.search && dispatch(orderListing({ token, url: location.search }));
    const timer = setTimeout(() => {
      const tdElements = document.querySelectorAll('td[role="rowheader"]');
  
      tdElements.forEach(td => {
        td.setAttribute('role', 'cell');
      });
    }, 1000);  
    return () => clearTimeout(timer);
  }, [dispatch, token, location.search]);

  const cellRendrer = useCallback((cell, row, column, index) => {
    if (column === "id") {
      return (
        <div id={row.id} className="clientOrderCol">
          <a
            href="#"
            className="download-link"
            onClick={() =>
              navigate(ROUTES.ORDER_DETAILS + "/" + row.id, {
                state: {
                  id: row.id,
                  row,
                },
              })
            }
          >
            {"PORD-" + row?.id}
          </a>
        </div>
      );
    }

    if (column === "order_invoice_number") {
      return (
        <div id={row.id}>
          {row?.order_invoice_number ? (
            <div
              onClick={() =>
                navigate(ROUTES.ORDER_DETAILS + "/" + row.id, {
                  state: {
                    id: row.id,
                    row,
                  },
                })
              }
            >
              <a href="#" className="download-link">
                {row?.order_invoice_number}
              </a>
            </div>
          ) : (
            <span>{notAvailable}</span>
          )}
        </div>
      );
    }

    if (column === "order_generate_date") {
      return (
        <div id={row.id}>
          <span className="role">
            {moment(row.order_generate_date).format(dateFormat)}
          </span>
        </div>
      );
    }
    if (column === "order_total_pages") {
      return <div>{row?.order_total_pages}</div>;
    }
    if (column === "order_delivery_date") {
      return (
        <div id={row.id}>
          <span className="role">
            {moment(row?.order_delivery_date).format(dateFormat)}
          </span>
        </div>
      );
    }

    if (column === "department") {
      return (
        <div id={row.id}>
          <span className="role capitalize">
            {row?.department ?? notAvailable}
          </span>
        </div>
      );
    }

    if (column === "user") {
      return (
        <div id={row.id}>
          <span className="role capitalize">
            {userId === row?.user?.id ? "Self" : row?.user?.name}
          </span>
        </div>
      );
    }

    if (column === "label_text") {
      return (
        <div>
          <span className={`_badge ${ORDER_STATUS_CLASS[row?.label_value]}`}>
            {row?.label_text}
          </span>
        </div>
      );
    }

    return row[column];
  }, []);

  const userWiseCondition =
    userDetails?.role === rootAdmin ||
    userDetails?.role === admin ||
    userDetails?.role === superAdmin ||
    userDetails?.role === postpaidRoot ||
    userDetails?.role === postpaidAdmin;

  const tableHead = [
    {
      key: "id",
      title: "ORDER NUMBER",
      sorting: true,
      searching: true,
      width: "15%",
    },
    {
      key: "order_invoice_number",
      title: "INVOICE NUMBER",
      sorting: true,
      searching: true,
      width: userWiseCondition ? "20%" : "25%",
    },
    {
      key: "order_generate_date",
      title: "DATE",
      sorting: true,
      searching: false,
      width: userWiseCondition ? "10%" : "20%",
    },
    {
      key: "order_total_pages",
      title: "TOTAL PAGES",
      sorting: true,
      searching: false,
      width: userWiseCondition ? "10%" : "20%",
    },
    {
      key: "order_delivery_date",
      title: "DATE OF DELIVERY",
      sorting: true,
      searching: false,
      width: userWiseCondition ? "15%" : "20%",
    },
    userWiseCondition && {
      key: "department",
      title: "DEPARTMENT",
      sorting: true,
      searching: false,
      width: "15%",
    },
    userWiseCondition && {
      key: "user",
      title: "ORDER BY",
      sorting: true,
      searching: false,
      width: "15%",
    },
    {
      key: "label_text",
      title: "STATUS",
      sorting: true,
      searching: false,
      width: "10%",
    },
  ];

  let columns = useMemo(() => [
    // {
    //   id: "select",
    //   header: ({ table }) => (
    //     <Checkbox
    //       checked={table.getIsAllPageRowsSelected()}
    //       onCheckedChange={(value) => {
    //         table.toggleAllPageRowsSelected(!!value)
    //         setSelectedRowIds((prev) =>
    //           prev.length === order_list.length ? [] : order_list.map((row) => row.id)
    //         )
    //       }}
    //       aria-label="Select all"
    //       className="translate-y-[2px]"
    //     />
    //   ),
    //   cell: ({ row }) => (
    //     <Checkbox
    //       checked={row.getIsSelected()}
    //       onCheckedChange={(value) => {
    //         row.toggleSelected(!!value)
    //         setSelectedRowIds((prev) =>
    //           value
    //             ? [...prev, row.original.id]
    //             : prev.filter((id) => id !== row.original.id)
    //         )
    //       }}
    //       aria-label="Select row"
    //       className="translate-y-[2px]"
    //     />
    //   ),
    //   enableSorting: false,
    //   enableHiding: false,
    //   size:50
    // },
    {
      id: "id",
      accessorKey: "id",
      header: "Order Number",
      enableSearch: true,
      size: 150,
      cell: ({ row }) => {
        return (
          <React.Fragment key={row.id}>
            <button
              id={row.original.id}
              href="#"
              className="download-link cursor-pointer font-bold text-start pl-2"
              onClick={() =>
                navigate(ROUTES.ORDER_DETAILS + "/" + row.original.id, {
                  state: {
                    id: row.original.id,
                    row: row.original,
                  },
                })
              }
            >
              {"PORD-" + row.original?.id}
            </button>
          </React.Fragment>
        )
      },
    },
    {
      accessorKey: "order_invoice_number",
      header: "Invoice Number",
      enableSearch: true,
      size: 170,
      cell: ({ row }) => {
        return row.original?.order_invoice_number ? (
          <span
            id={row.id}
            aria-label="Invoice Number"
            className="cursor-pointer"
            onClick={() =>
              navigate(ROUTES.ORDER_DETAILS + "/" + row.original.id, {
                state: {
                  id: row.original.id,
                  row: row.original,
                },
              })
            }
          >
            <span href="#" className="download-link">
              {row.original?.order_invoice_number}
            </span>
          </span>
        ) : (
          <span id={row.id}>{notAvailable}</span>
        )
      }
    },
    {
      accessorKey: "order_generate_date",
      header: "Date",
      cell: ({ row }) => {
        return (
          <React.Fragment key={row.id}>
            <span className="role" id={row.id}>
              {moment(row.original?.order_generate_date).format(dateFormat)}
            </span>
          </React.Fragment>
        )
      }
    },
     {
      accessorKey: "order_total_pages",
      header: "Total Pages",
    },
     {
      accessorKey: "order_delivery_date",
      header: "Date Of Delivery",
      cell: ({ row }) => {
        return (
          <React.Fragment key={row.id}>
            <span className="role" id={row.id}>
              {moment(row.original?.order_delivery_date).format(dateFormat)}
            </span>
          </React.Fragment>
        )
      },
      size: 170
    },
    {
      accessorKey: "label_text",
      header: "Status",
      cell: ({ row }) => {
        return (
          <React.Fragment key={row.id}>
            <span className={`_badge ${ORDER_STATUS_CLASS[row.original?.label_value]}`} id={row.id}>
              {row.original?.label_text}
            </span>
          </React.Fragment>
        )
      },
    }
  ], [userId, navigate, userWiseCondition])

  if(userWiseCondition) {

    const newColumns = [
      ...columns.slice(0, 4),
      {
        accessorKey: "department",
        header: "Department",
        cell: ({ row }) => {
          return (
            <React.Fragment key={row.id} >
              <span className="role capitalize" id={row.id}>
                {row.original?.department ?? notAvailable}
              </span>
            </React.Fragment>
          )
        }
      },
      {
        accessorKey: "user",
        header: "Order By",
        cell: ({ row }) => {
          return (
            <React.Fragment key={row.id}>
              <span className="role capitalize" id={row.id}>
                {userId === row.original?.user?.id ? "Self" : row.original?.user?.name}
              </span>
            </React.Fragment>
          )
        }
      },
      ...columns.slice(4)
    ];
    columns = newColumns;
  }

  return (
    <div className="dt-responsive">
      {/* <DataTablesComp
        tableHead={tableHead}
        data={order_listing?.order_list}
        renderCell={cellRendrer}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        setPageSize={setPageSize}
        pageSize={pageSize}
        type="order"
        search={true}
        pageSizing={true}
        paginationBar={true}
        // pageSize={true}
        tableDescription="Order List"
      /> */}

      <DataTablev2
        columns={columns}
        totalRecords={total_records}
        searchableColumns={searchableColumns}
        search={true}
        title={"Order List"}
        data={order_list ? order_list : []}
      />
    </div>
  );
};
export default OrderTable;
