import React from "react";
import {Button} from "components/Buttonv2";
import FocusTrap from "focus-trap-react";
import { Clear } from "assets/images";
import { Dialog, DialogContent, DialogFooter, DialogHeader } from "components/Dialog";

const ConfirmPopUp = ({
  show,
  handleClose,
  onConfirmHandler,
  heading,
  Icon,
  confirmMsg,
  content,
  id
}) => {
  const onHide = () => {
    handleClose();
    console.log(id);
    setTimeout(() => {
      const deletebtnID = document.getElementById('rem_del'+id)
      deletebtnID.focus();
    }, 500);
  };
  const onConfirmation = () => {
    onConfirmHandler();
  };

  return (
    <Dialog
      open={show}
      onOpenChange={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="trash-Modal"
    >
      <FocusTrap active={show}>
        <DialogContent>
          <DialogHeader>
            <h2 className="flex items-center capitalize font-semibold text-2xl">
              {Icon && (
                <Button variant="destructive" className="me-3" aria-label="Delete File">
                  <Icon />
                </Button>
              )}
              {heading}
            </h2>
          </DialogHeader>
            <p tabIndex="-1">{confirmMsg}</p>
            {content && <div className="mb-2">{content}</div>}
            
          <DialogFooter>
            <Button
              onClick={onConfirmation}
              tabIndex="0"
            >
              Confirm
            </Button>
            <Button
              onClick={onHide}
            >
              Cancel
            </Button>
          </DialogFooter>
        </DialogContent>
      </FocusTrap>
    </Dialog>
  );
};

export default ConfirmPopUp;
