// import React from "react";
// import { useSelector } from "react-redux";
// import { Document, Page, Text, pdf, View, StyleSheet, Image } from '@react-pdf/renderer';
// import { OrderSuccess } from "assets/images";
// import { MainLogo } from "assets/images";
// import { INVOICE_TITLE } from "constants/title";
// import { currencyFormatter } from "util/helpers";

// const PaymentSucceed = ({data, address,}) => {
//   const wallet_balance = useSelector((state) => state.app.userAuth.user.wallet_balance);

//   const commonStyles = StyleSheet.create({
//     pageStyle: {
//       flexDirection: "column",
//       backgroundColor: "white",
//       display: "flex",
//       flexDirection: "column",
//       position: "relative",
//       section: {
//         flexDirection: "row",
//         justifyContent: "space-between",
//         marginBottom: "2%",
//         borderBottom: "1px solid black",
//         paddingBottom: "2%",
//         display: "flex",
//         section_left: {
//           width: "70%",
//         },
//         section_right: {
//           width: "30%",
//           display: "flex",
//           justifyContent: "flex-end",
//           datecolor: {
//             fontWeight: "600",
//             color: "#000",
//             fontSize: "10px",
//           },
//           totalamount: {
//             textAlign: "right",
//           },
//           flexsec: {
//             display: "flex",
//             flexDirection: "row",
//             justifyContent: "space-between",
//             width: "150px",
//           },
//           totallabel: {
//             textAlign: "left",
//             width: "40%",
//           },
//           labelheading: {
//             textAlign: "left",
//             width: "45%",
//           },
//           divider: {
//             width: "10%",
//             marginRight: "5%",
//           },
//         },
//       },
//       footer_section: {
//         position: "absolute",
//         bottom: "-30px",
//         footer: {
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           justifyContent: "center",
//           backgroundColor: "#cc6f26",
//           width: "100%",
//           height: "50px",
//         },
//         footerheading: {
//           fontSize: "12px",
//         },
//         pageNo: {
//           fontSize: "10px",
//         },
//       },
//     },

//     topbar: {
//       backgroundColor: "#cc6f26",
//       height: "10px",
//     },
//     marginTop: {
//       marginTop: "20px",
//     },
//     marginLeftRight: {
//       marginLeft: "20px",
//       marginRight: "20px",
//     },
//     borderNone: {
//       border: "none",
//     },
//   });
//   const styles = StyleSheet.create({
//     logo: {
//       width: 150,
//       objectFit: "cover",
//       marginTop: "12px",
//     },

//     heading: {
//       fontSize: "12px",
//       marginBottom: "5px",
//     },
//     p: {
//       fontSize: "8px",
//       margin: "5px 0px",
//     },
//     note: {
//       width: "70%",
//       fontSize: "10px",
//     },
//   });
//   const { logo, heading, p, note } = styles;

//   const { pageStyle, marginTop, marginLeftRight, borderNone, topbar } =
//     commonStyles;
//   const { section, footer_section } = pageStyle;
//   const { section_left, section_right } = section;
//   const { footer, pageNo, footerheading } = footer_section;
//   const { datecolor, totalamount, flexsec, totallabel, labelheading, divider } =
//     section_right;
//   // Create Document Component

//   const tableHead = ["Type", "Delivery", "Pages", "Cost"];
  
//   const handleDownloadInvoice = (event) => {
//     event.preventDefault();
//     const pdfContent = (
//       <Document>
//         <Page size="A4" style={pageStyle} wrap>
//           <View style={topbar}></View>
//           <View style={[section, marginTop, marginLeftRight]} fixed></View>
//           <View style={section_left}>
//             <Image style={logo} src={MainLogo} />
//           </View>
//           <View style={section_right}>
//             <Text style={heading}>247 Accessible Documents Pte. Ltd.</Text>
//             <Text style={p}>20 Cecil Street,</Text>
//             <Text style={p}>#07-03 PLUS,</Text>
//             <Text style={p}>Singapore 049705</Text>
//           </View>
//           <View style={[section, marginLeftRight]} fixed>
//             <View style={section_left}>
//               <Text style={p}>
//                 BILL TO: <Text style={p}>{address?.org_name ?? ""}</Text>
//               </Text>
//               <Text style={p}>
//                 Address:{" "}
//                 <Text style={p}>
//                   {`${address?.address1 ?? ""}, ${address?.address2 ?? ""}, ${address?.city ?? ""}, ${address?.state ?? ""}, ${address?.country ?? ""}`}
//                 </Text>
//               </Text>
//               <Text style={p}>
//                 Postal: <Text style={p}>{address?.postal_code ?? ""}</Text>
//               </Text>
//             </View>
//             <Text>Invoice Content</Text>
//             <Text>Wallet Balance: {wallet_balance}</Text>
//             <View style={[section, footer_section]} fixed>
//               <View style={footer}>
//                 {/* <Text style={footerheading}>
//                   Powered By 24/7 Accessible Documents
//                 </Text> */}
//               </View>
//             </View>
//           </View>
//         </Page>
//       </Document>
//     );
  
//     pdf(pdfContent).toBlob().then((pdfBlob) => {
//       const url = URL.createObjectURL(pdfBlob);
//       const downloadLink = document.createElement('a');
//       downloadLink.href = url;
//       downloadLink.download = 'invoice.pdf';
//       downloadLink.click();
//       URL.revokeObjectURL(url);
//     });
//   };
  
//   return (
//     <div className="customCard1 orderBlock1">
//       <div className="orderSuccessful">
//         <figure className="success-icon flex justify-center items-center cust_place">
//           <OrderSuccess aria-hidden="true" focusable="false" />
//         </figure>
//         <strong>Payment Successful!</strong>
//         <p>Your invoice has been sent to you via email.</p>
//         <button onClick={handleDownloadInvoice}>Download Invoice</button>
//       </div>
//     </div>
//   );
// };
 
// export default PaymentSucceed;


//// Above is working ///////

import React, { useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  pdf,
} from "@react-pdf/renderer";
import { MainLogo } from "assets/images";
import { INVOICE_TITLE } from "constants/title";
import { OrderSuccess } from "assets/images";
import { currencyFormatter } from "util/helpers";
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import PaymentInvoiceTable from "pages/Admin/Update/Sections/Invoice/PaymentInvoiceTable.js"
import { PaymentInvoice } from "redux/asyncApi/uploadApi";

// Function to generate NV-YYYY-Number format ID
const generateUniqueId = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const date = String(currentDate.getDate()).padStart(2, '0');
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');

  return `INV-${year}-${month}-${date}${hours}${minutes}${seconds}`;
};

const CurrentPaymentDate = moment().format('YYYY-MM-DD');

const PaymentSucceed = ({
  data,
  total_amount,
  saving_amount,
  final_payble_amount,
  perPageData,
  order_generate_date,
  order_details,
  amount,
  wallet_id,
}) => {
  // Generate unique ID for order invoice number
  const order_invoice_number = generateUniqueId();
  const wallet_balance = useSelector((state) => state.app.userAuth.user.wallet_balance);
  const {
    userAuth: { token },
  } = useSelector((state) => state.app);
  const dispatch=useDispatch();

  // Create styles

  const commonStyles = StyleSheet.create({
    pageStyle: {
      flexDirection: "column",
      backgroundColor: "white",
      display: "flex",
      position: "relative",
    },
    section: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: "2%",
      borderBottom: "1px solid black",
      paddingBottom: "2%",
      display: "flex",
    },
    lastsection: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: "2%",
      borderBottom: "1px solid black",
      paddingBottom: "2%",
      display: "flex",
    },
    section_left: {
      width: "70%",
    },
    section_right: {
      width: "30%",
      display: "flex",
      justifyContent: "flex-end",
    },
    datecolor: {
      fontWeight: "600",
      color: "#000",
      fontSize: "10px",
    },
    totalamount: {
      textAlign: "right",
    },
    flexsec: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "150px",
    },
    totallabel: {
      textAlign: "left",
      width: "40%",
    },
    labelheading: {
      textAlign: "left",
      width: "45%",
    },
    divider: {
      width: "10%",
      marginRight: "5%",
    },
  });

  const footerStyles = StyleSheet.create({
    footer_section: {
      position: "absolute",
      bottom: "-30px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#cc6f26",
      width: "100%",
      height: "50px",
    },
    footerheading: {
      fontSize: "12px",
    },
    pageNo: {
      fontSize: "10px",
    },
  });

  const styles = StyleSheet.create({
    logo: {
      width: 150,
      objectFit: "cover",
      marginTop: "12px",
    },
    heading: {
      fontSize: "12px",
      marginBottom: "5px",
    },
    p: {
      fontSize: "8px",
      margin: "5px 0px",
    },
    note: {
      width: "70%",
      fontSize: "10px",
    },
    topbar: {
      backgroundColor: "#cc6f26",
      height: "10px",
    },
    marginTop: {
      marginTop: "20px",
    },
    marginLeftRight: {
      marginLeft: "20px",
      marginRight: "20px",
    },
    borderNone: {
      border: "none",
    },
  });

  const { billingInfo } =
    useSelector((state) => state.payment) || {};
    const {
    result: { address, name },
  } = billingInfo || {};
  const { logo, heading, p, note } = styles;
  const { pageStyle, section, section_left, section_right } = commonStyles;
  const { datecolor, totalamount, flexsec, totallabel, labelheading, divider } =
    commonStyles;

  const { footer_section, footerheading, pageNo } = footerStyles;

  // Create Document Component

  const tableHead = ["Cost"];

  const handleDownloadInvoice = () => {
    const pdfContent = (
      <Document title="PAYMENT INVOICE">
            <Page
              size="A4"
              style={pageStyle}
              wrap
              pageNumber="1"
            >
              <View style={[styles.topbar]}></View>
              <View style={[section, styles.marginTop, styles.marginLeftRight]} fixed>
                <View style={section_left}>
                  <Image style={logo} src={MainLogo} />
                </View>
                <View style={section_right}>
                  <Text style={heading}>247 Accessible Documents Pte. Ltd.</Text>
                  <Text style={p}>20 Cecil Street,</Text>
                  <Text style={p}>#07-03 PLUS,</Text>
                  <Text style={p}>Singapore</Text>
                  <Text style={p}>049705</Text>
                </View>
              </View>
              <View style={[section, styles.marginLeftRight]} fixed>
                <View style={section_left}>
                  <Text style={p}>
                    BILL TO: <Text style={p}>{name ?? ""}</Text>
                  </Text>
                  <Text style={p}>
                    Address:{" "}
                    <Text style={p}>
                      {`${address?.line1 ?? "" },
                      ${address?.line2 ?? "" },
                      ${address?.city ?? "" },
                      ${address?.state ?? "" },
                      ${address?.country ?? "" }`}
                    </Text>
                  </Text>
                  <Text style={p}>
                    Postal: <Text style={p}>{address?.postal_code ?? ""}</Text>
                  </Text>
                </View>
                <View style={section_right}>
                  <Text style={p}>
                    INVOICE #:{" "}
                    <Text style={datecolor}>{order_invoice_number}</Text>
                  </Text>
                  <Text style={p}>
                    DATE: <Text style={datecolor}>{CurrentPaymentDate}</Text>
                  </Text>
                </View>
              </View>
              <PaymentInvoiceTable amount={amount}/>
              <View style={[section, styles.marginLeftRight]} fixed>
                <View style={section_left}>
                  {/* Additional content if needed */}
                </View>
                <View style={section_right}>
                  <View style={[flexsec]}>
                    <Text style={[p, totallabel]}>TOTAL</Text>
                    <Text style={[divider]}>:</Text>
                    <Text style={[labelheading]}>
                    {(currencyFormatter(amount))}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={[footer_section]}></View>
            </Page>
      </Document>
    );
    pdf(pdfContent).toBlob().then((pdfBlob) => {
      const file = new File([pdfBlob], 'invoice.pdf', { type: 'application/pdf' });
      const values = {
        invoice_file: file,
        wallet_id: {wallet_id, amount},
      };
      // const url = URL.createObjectURL(pdfBlob);
      // const downloadLink = document.createElement('a');
      // downloadLink.href = url;
      // downloadLink.download = 'invoice.pdf';
      // downloadLink.click();
      // URL.revokeObjectURL(url);
      dispatch(PaymentInvoice({ token, values }));
    });
  };

  useEffect(() => {
    handleDownloadInvoice();
  }, []);

  const handleManualDownload = (event) => {
    event.preventDefault();
    handleDownloadInvoice();
  };

  return (
    <div className="customCard1 orderBlock1">
      <div className="orderSuccessful">
        <figure className="success-icon flex justify-center items-center cust_place">
          <OrderSuccess aria-hidden="true" focusable="false" />
        </figure>
        <strong>Payment Successful!</strong>
        <p>Your invoice has been sent to you via email.</p>
      </div>
    </div>
  );
};

export default PaymentSucceed;

