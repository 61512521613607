import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { UploadIcon, Mobilemenu, MainLogo } from "assets/images";
import "./header.scss";
import { ROUTES } from "routes/constant";
import { Button } from "components/Buttonv2";

const Header = React.forwardRef(({ title, setIsToggle, isToggle, isSubscribed }, ref) => {

  const navigate = useNavigate()
  const location = useLocation();
  const {pathname} = location;

  const showUploadButton = isSubscribed && pathname !== ROUTES.UPDATE

  return (
    <div className="mainheaderOuter">
      {/* <div className='mobileHeader d-none'>
        <div className='items-center'>
          <div xs={10} md={6}>
            <Link to="/" className='sideBar-Logo'  >
              <img src={MainLogo} alt='247 Accessible Documents Logo' className='img-fluid logo-desktop' />
            </Link> 
          </div>
          <div xs={2} md={6}>
            <button aria-haspopup="menu" aria-label="open navigation" className={`mobileToggle d-none ${isToggle ? 'menuClose' : 'menuOpen'}`} onClick={() => { setIsToggle(!isToggle) }} >
              <Mobilemenu className='menu-bar' role="img" />
            </button>
          </div>
        </div>
      </div> */}
      <div className="dashBoardHeaderwrapper">
        <div className="dashBoardHeader">
          <div className="flex flex-row items-center justify-between">
            <h1 className="mainTitle" ref={ref} tabIndex="-1">
              {title}
            </h1>
            {showUploadButton && <div>
                <Button
                  size="xl"
                  onClick={() => {
                    navigate(ROUTES.UPDATE)
                  }}
                >
                  <UploadIcon aria-hidden="true" focusable="false" className="mr-2" /> Upload
                </Button>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
});

export default Header;
