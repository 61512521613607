export const validationMsg = {
  // Required fields
  fNameReq: "Please enter your First Name.",
  lNameReq: "Please enter your Last Name.",
  emailReq: "Please enter Email address.",
  mobileReq: "Please enter phone number.",
  countryCodeReq:
    "Select the country code or enter the number along with the country code.",
  departmentReq: "Please enter department.",
  designationReq: "Please enter designation.",
  companyReq: "Please enter Company Name.",
  passReq: "Please enter Password.",
  newPassReq: "Please enter new password.",
  updatePassReq: "Please enter update password.",
  oldPassReq: "Please enter old password.",
  confirmPassReq: "Please enter Confirm Password.",
  confirmNewPassReq: "Please enter confirm password.",
  tAndConReq: "Kindly acknowledge the Terms and Conditions.",
  address1Req: "Please enter Address1.",
  address2Req: "Please enter Address2.",
  stateReq: "Please enter State.",
  cityReq: "Please enter City.",
  postalReq: "Please enter Zip-code.",
  countryReq: "Please enter country.",
  normalPdfReq: "Please enter pdf price.",
  normalDocReq: "Please enter doc price.",
  normalPptReq: "Please enter ppt price.",
  normalPdfFormPriceReq: "Please enter pdf form price.",
  expeditedPdfPriceReq: "Please enter expedited pdf price.",
  expeditedDocPriceReq: "Please enter expedited doc price.",
  expeditedPptPriceReq: "Please enter expedited ppt price.",
  rushPptPriceReq: "Please enter rush ppt price.",
  rushDocPriceReq: "Please enter rush doc price.",
  rushPdfPriceReq: "Please enter rush Pdf price.",
  reqAmount: "Please enter amount.",
  remarksReq: "Please enter remarks for rejection.",
  accComReq: "Please select compliance level.",
  chkClkCnstReq: "Required field.",
  descriptionReq: "Please enter description.",
  imageReq: "Image Should not be empty.",
  statusReq: "Please enter status.",
  dateReq: "Please enter date.",
  holidayReq: "Please enter holiday.",
  couponReq: "Please enter coupon code.",
  uploadFileReq: "Upload file is required.",
  pacFileReq: "Pac file is required.",
  accFileReq: "Accesibility file is required.",
  commentMsgReq:"Please enter Message.",

  //conditanal error messeges
  validEmail: "Please enter a valid Email.",
  validFname: "First Name cannot include special characters.",
  validLname: "Last Name cannot include special characters.",
  validPhone: "Please enter a valid phone.",
  validCofirmPass: "Password and Confirm Password do not match.",
  validNewCofirmPass: "New password and Confirm Password do not match.",
  validUpdateCofirmPass: "Update password and Confirm Password do not match.",
  validAmount: "Please enter valid amount.",
  validDescription: (maxLimit) =>
    `The description should be under ${maxLimit} characters`,
};
