import axios from 'axios'
const axiosApiInstance = axios.create({
  timeout:1000*60*10,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosApiInstance.interceptors.request.use(function (config) {
  // Do something before request is sent
  // config.headers = {
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type':'application/json'
  // }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
axiosApiInstance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data

  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

export default axiosApiInstance  