import React, { useEffect, useState } from "react";

import ClientOrdersTable from "./ClientOrdersTable";
import { useDispatch, useSelector } from "react-redux";
import {
  generateMultipleInvoices,
  getClientsOrderList,
} from "redux/asyncApi/orderApi";
import Button from "components/Button";
import CustomReactSelect from "components/CustomReactSelect";
import { getCustomUsers } from "redux/asyncApi/userApi";
import "./clientOrders.scss";
import { CLIENT_ORDER_TITLE } from "constants/title";
import { useLocation } from "react-router-dom";
const ClientOrders = () => {
  document.title = CLIENT_ORDER_TITLE;
  const dispatch = useDispatch();
  const { customUsers } = useSelector((state) => state.user);
  const { clients_order_listing } = useSelector((state) => state.order) || {};
  const [selectedUser, setSelectedUser] = useState("");
  const {
    userAuth: { token },
  } = useSelector((state) => state.app) || {};


  // useEffect(() => {
  //   if (!selectedUser) {
  //     dispatch(getCustomUsers(token));
  //   } else {
  //     const values = {
  //       selectedUserId: selectedUser,
  //     };
  //     dispatch(getClientsOrderList({ token, values }));
  //   }
  // }, [selectedUser]);

  const location = useLocation();

  useEffect(() => {

    if (!selectedUser) {
      location.search && dispatch(getCustomUsers({ token, url: location.search }));
    } else {
      const values = {
        selectedUserId: selectedUser,
      };
      location.search && dispatch(getClientsOrderList({ token, url: location.search, values }));
    }
  }, [dispatch, token, location.search, selectedUser]);

  useEffect(() => {
    if (customUsers?.data?.length > 0)
      setSelectedUser(customUsers?.data?.[0]?.value);
  }, [customUsers]);
  const selectOrganization = (e) => {
    setSelectedUser(e.value);
  };

  return (
    <div className="mainWrapper UM-Wrapper order-management-page">
      <div className="main-content order-table">
        {/* {selectedUser && ( */}
        <ClientOrdersTable
          selectedUserId={selectedUser}
          orderData={clients_order_listing}
          customButton={
            <CustomReactSelect
              className=""
              value={
                customUsers?.data?.filter(
                  (x) => x.value === parseInt(selectedUser)
                )?.[0]
              }
              data={customUsers?.data}
              onSelectHandler={selectOrganization}
              showOptionBadge={true}
              isClearable={false}
            />
          }
        />
        {/* )} */}
      </div>
    </div>
  );
};

export default ClientOrders;
