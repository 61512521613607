import React from "react";
import { ORDER_MANAGEMENT } from "constants/title";
import "./order.scss";
import OrderTable from "./OrderTable";

const OrderManagement = () => {
  document.title = ORDER_MANAGEMENT;
  return (
    <div className="mainWrapper UM-Wrapper order-management-page">
      <div className="main-content order-table">
        <OrderTable />
      </div>
    </div>
  );
};

export default OrderManagement;
