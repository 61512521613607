import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  AddUserIcon,
  EditIcon,
  EmailSentIcon,
  VerticalDotsIcon,
} from "assets/images";
import { MdOutgoingMail } from "react-icons/md";

import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchUserListing,
  makeInactiveUser,
  switchUser,
  resendMail,
} from "redux/asyncApi/userApi";
import { removeMessage } from "redux/Slices/userSlice";
import _ from "lodash";
import Datatable from "components/dataTable";
import moment from "moment";
import { toast } from "react-toastify";
import {
  bytesToSize,
  dateFormat,
  getFirstAndLast,
  noRecordFound,
  notAvailable,
  usersTypes,
} from "util/helpers";
import ButtonL from "components/Button";
import { Button } from "components/Buttonv2";
import { ROUTES } from "routes/constant";
import "./userManagement.scss";
import ConfirmPopUp from "components/Confirm/ConfirmPopUp";
import { message } from "util/message";
import DataTablev2 from "components/dataTablev2";
import { Select } from "components/SelectV2";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "components/Dropdown";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "components/Dialog";
import { Edit2Icon, Edit3, MailPlus, MoreHorizontal } from "lucide-react";
import { handleUserListTable } from "../../../../src/Custom";


const DummyTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [focus, setFocus] = useState();
  const [show, setShow] = useState(false);
  const [userId, setUserId] = useState();
  const [prevPerPage, setPrevPerPage] = useState(null);

  //Redux state
  const {
    loading,
    userAuth: {
      token,
      user: { role },
    },
  } = useSelector((state) => state.app) || {};

  const { user_listing, addUpdateUser, focusId } =
    useSelector((state) => state.user) || {};

  const { user_list, status, total_records } = user_listing;
  const { success: Success, message: toastMsg } = addUpdateUser || {};

  //Redux function
  const dispatch = useDispatch();

  //Router functions
  const navigate = useNavigate();
  //const location = useLocation();

  //User roles
  const {
    user,
    admin,
    superAdmin,
    postpaidRoot,
    rootAdmin,
    postpaidAdmin,
    billingAdmin,
    companyUser,
  } = usersTypes;

  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const perPageValue = urlParams.get("per_page");
    if (prevPerPage !== perPageValue) {
      handleUserListTable();
      setPrevPerPage(perPageValue);
    }
  }, [location.search, prevPerPage]);

  const [prevSort, setPrevSort] = useState(null);
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const sortValue = urlParams.get("sort");
    if (prevSort !== sortValue || sortValue === null) {
      handleUserListTable();
      setPrevSort(sortValue);
    }
  }, [location.search, prevSort]);

  useEffect(() => {
    location.search && dispatch(fetchUserListing({ token, url: location.search }));
  }, [dispatch, token, location.search]);

  useEffect(() => {
    if (Success) {
      location.search && dispatch(fetchUserListing({ token, url: location.search }));
    }
  }, [Success, dispatch, location.search, token]);

  useEffect(() => {
    toast(toastMsg, { className: Success ? "_success" : "_error" });
    return () => {
      dispatch(removeMessage());
    };
  }, [toastMsg]);

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  //This method handle make admin and active or inactive user
  const userType = (type, id) => {
    if (type === "user") {
      setFocus(id);
      const values = { user_id: id };
      dispatch(makeInactiveUser({ token, values }));
    } else {
      setFocus(id);
      const values = { id, user_type: type };
      dispatch(switchUser({ token, values }));
    }
  };

  //This method handle resend email to user when existing email is expired.
  const resendEmailHandler = () => {
    const values = {
      user_id: userId,
    };
    dispatch(resendMail({ token, values })).then(({ payload }) => {
      if (payload.status) {
        handleClose();
      }
    });
  };

  const onConfirmHandler = () => {
    resendEmailHandler();
  };

  // const roleSuperAdmin = role === superAdmin;

  //This array hanldle columns of datatable
  // const tableHead = [
  //   {
  //     key: "name",
  //     title: "USER LIST",
  //     sorting: true,
  //     searching: true,
  //     width: roleSuperAdmin ? "17%" : "22%",
  //   },
  //   {
  //     key: "email",
  //     title: "EMAIL",
  //     sorting: true,
  //     searching: true,
  //     width: roleSuperAdmin ? "15%" : "15%",
  //   },
  //   {
  //     key: "user_status",
  //     title: "STATUS",
  //     sorting: true,
  //     searching: false,
  //     width: roleSuperAdmin ? "8%" : "10%",
  //   },
  //   {
  //     key: "is_admin",
  //     title: "ROLE",
  //     sorting: true,
  //     searching: false,
  //     width: roleSuperAdmin ? "10%" : "13%",
  //   },
  //   {
  //     key: "department",
  //     title: "Department",
  //     sorting: true,
  //     searching: false,
  //     width: roleSuperAdmin ? "10%" : "10%",
  //   },
  //   {
  //     key: "total_usage",
  //     title: "SPACE USED",
  //     sorting: true,
  //     searching: false,
  //     width: roleSuperAdmin ? "10%" : "10%",
  //   },
  //   {
  //     key: "loggedin_time",
  //     title: "LAST LOGGED IN",
  //     sorting: true,
  //     searching: false,
  //     width: roleSuperAdmin ? "10%" : "10%",
  //   },
  //   {
  //     key: "action",
  //     title: "ACTION",
  //     sorting: false,
  //     searching: false,
  //     width: roleSuperAdmin ? "10%" : "10%",
  //     theadId: "action",
  //   },
  // ];
  // if (role === superAdmin) {
  //   tableHead.splice(6, 0, {
  //     key: "created_at",
  //     title: "CREATED ON",
  //     sorting: true,
  //     searching: false,
  //     width: roleSuperAdmin ? "10%" : "10%",
  //   });
  // }

  // This function hanldle columns data of datatable
  // const cellRendrer = useCallback(
  //   (cell, row, column, index, tHeadId) => {
  //     if (column === "name") {
  //       return (
  //         <div className="user-group">
  //           {row?.image ? (
  //             <img
  //               src={row?.image}
  //               className="img-fluid table_picture "
  //               alt={""}
  //             />
  //           ) : (
  //             <span className="table_picture uppercase">
  //               {row?.first_name && row?.last_name
  //                 ? getFirstAndLast(row?.first_name, row?.last_name)
  //                 : ""}
  //             </span>
  //           )}
  //           <span className="full-name ms-2 capitalize">{`${row?.first_name} ${row?.last_name}`}</span>
  //         </div>
  //       );
  //     }

  //     if (column === "email") {
  //       return (
  //         <div id={row.id} className="flex items-center">
  //           <a href={`mailto:${row?.email}`} className="text_dec_none">
  //             {row?.email}
  //           </a>
  //         </div>
  //       );
  //     }
  //     if (column === "user_status") {
  //       return (
  //         <div>
  //           <span className={`_badge ${cell === "1" ? "active" : "inactive"}`}>
  //             {cell === "1" ? "Active" : "Inactive"}
  //           </span>
  //         </div>
  //       );
  //     }
  //     if (column === "is_admin") {
  //       return (
  //         <div id={row.id}>
  //           <span className="role capitalize">{row?.role_name}</span>
  //         </div>
  //       );
  //     }

  //     if (column === "department") {
  //       return (
  //         <div id={row.id}>
  //           <span className="role capitalize">
  //             {row?.department ?? notAvailable}
  //           </span>
  //         </div>
  //       );
  //     }

  //     if (column === "total_usage") {
  //       return (
  //         <div id={row.id}>
  //           <span className="role capitalize">
  //             {bytesToSize(row?.total_usage)}
  //           </span>
  //         </div>
  //       );
  //     }

  //     if (column === "created_at") {
  //       return (
  //         <div id={row.id}>
  //           {row?.created_at
  //             ? moment(row.created_at).format(dateFormat)
  //             : notAvailable}
  //         </div>
  //       );
  //     }
  //     if (column === "loggedin_time") {
  //       return (
  //         <div id={row.id}>
  //           {row?.loggedin_time
  //             ? moment(row.loggedin_time).format(dateFormat)
  //             : notAvailable}
  //         </div>
  //       );
  //     }
  //     if (column === "action") {
  //       const allowOption =
  //         role === postpaidRoot || role === rootAdmin || role === superAdmin;

  //       const resendUserId = "resend_btn" + row?.id;
  //       const editUserId = "edit_btn" + row?.id;
  //       return (
  //         <div className="table-button flex">
  //           <div className="action_btn_group">
  //             {!row?.email_verify && (
  //               <button
  //                 title="Resend-email"
  //                 className={`btn-table id${row.id} resend_btn`}
  //                 data-active={focusId === row.id ? true : false}
  //                 id={resendUserId}
  //                 aria-labelledby={`${tHeadId} ${resendUserId} action`}
  //                 type="button"
  //                 autoFocus={focusId === row.id ? true : false}
  //                 onClick={() => {
  //                   handleShow();
  //                   setUserId(row?.id);
  //                 }}
  //               >
  //                 <EmailSentIcon aria-hidden="true" focusable="false" />
  //               </button>
  //             )}
  //             <button
  //               title="Edit-user"
  //               className={`btn-table id${row.id}`}
  //               data-active={focusId === row.id ? true : false}
  //               id={editUserId}
  //               aria-labelledby={`${tHeadId} ${editUserId} action`}
  //               type="button"
  //               autoFocus={focusId === row.id ? true : false}
  //               onClick={() => {
  //                 if (row?.user_role === postpaidRoot) {
  //                   navigate(
  //                     ROUTES.UPDATE_CUSTOM_USER.replace(/:id/g, row.id),
  //                     {
  //                       state: {
  //                         id: row.id,
  //                         mode: "edit",
  //                         user_role: row?.user_role,
  //                       },
  //                     }
  //                   );
  //                 } else {
  //                   navigate(ROUTES.UPDATE_USER.replace(/:id/g, row.id), {
  //                     state: { id: row.id, mode: "edit", user_role: row?.user_role },
  //                   });
  //                 }
  //               }}
  //             >
  //               <EditIcon aria-hidden="true" focusable="false" />
  //             </button>
  //           </div>
  //           <Dropdown className="btn-table ms-3 ">
  //             <Dropdown.Toggle
  //               variant=""
  //               data-active={focus === row.id ? true : false}
  //               autoFocus={focus === row.id ? true : false}
  //               className="border-btn btn-table"
  //               id="dropdown-basic"
  //               aria-label={`Status Options ${row?.full_name}`}
  //             >
  //               <VerticalDotsIcon aria-hidden="true" focusable="false" />
  //             </Dropdown.Toggle>
  //             <Dropdown.Menu>
  //               <Dropdown.Item
  //                 aria-label={
  //                   row.user_status === "1" ? "Inactive User" : "Active User"
  //                 }
  //                 id={cell}
  //                 tp="user"
  //                 onKeyDown={() => userType("user", row?.id)}
  //                 onClick={() => userType("user", row?.id)}
  //               >
  //                 {row.user_status === "1" ? "Inactive User" : "Active User"}
  //               </Dropdown.Item>
  //               {allowOption && (
  //                 <>
  //                   {row?.user_role !== admin &&
  //                     row?.user_role !== postpaidAdmin &&
  //                     row?.user_role !== billingAdmin &&
  //                     row?.user_role !== postpaidRoot &&
  //                     row?.user_role !== rootAdmin &&
  //                     row?.is_admin === "N" && (
  //                       <Dropdown.Item
  //                         aria-label="make admin"
  //                         className="capitalize"
  //                         id={cell}
  //                         tp="admin"
  //                         onKeyDown={() => userType("admin", row?.id)}
  //                         onClick={() => userType("admin", row?.id)}
  //                       >
  //                         make admin
  //                       </Dropdown.Item>
  //                     )}

  //                   {row?.user_role === postpaidAdmin &&
  //                     row?.is_admin === "Y" && (
  //                       <Dropdown.Item
  //                         aria-label="company user"
  //                         className="capitalize"
  //                         id={cell}
  //                         tp="company user"
  //                         onKeyDown={() => userType("company", row?.id)}
  //                         onClick={() => userType("company", row?.id)}
  //                       >
  //                         make company user
  //                       </Dropdown.Item>
  //                     )}

  //                   {(row?.user_role === admin ||
  //                     row?.user_role === companyUser) && (
  //                       <Dropdown.Item
  //                         aria-label="make billing admin"
  //                         className="capitalize"
  //                         id={cell}
  //                         tp="admin"
  //                         onKeyDown={() => userType("billing_admin", row?.id)}
  //                         onClick={() => userType("billing_admin", row?.id)}
  //                       >
  //                         make billing admin
  //                       </Dropdown.Item>
  //                     )}
  //                 </>
  //               )}
  //             </Dropdown.Menu>
  //           </Dropdown>
  //         </div>
  //       );
  //     }
  //     return row[column];
  //   },
  //   [focus]
  // );


  const columns = [
    {
      header: "User List",
      accessorKey: "name",
      cell: ({ row }) => (
        <div className="user-group">
          {row?.original?.image ? (
            <img
              src={row?.original?.image}
              className="img-fluid table_picture "
              alt={""}
            />
          ) : (
            <span className="table_picture uppercase" aria-hidden="true">
              {row?.original?.first_name && row?.original?.last_name
                ? getFirstAndLast(row?.original?.first_name, row?.original?.last_name)
                : ""}
            </span>
          )}
          <span id={`${row?.id}_id`} className="full-name ms-2 capitalize">{`${row?.original?.first_name} ${row?.original?.last_name}`}</span>
        </div>
      ),
    },
    {
      header: "Email",
      accessorKey: "email",
      cell: ({ row }) => (
        <div id={row?.original?.id} className="flex items-center">
          <a href={`mailto:${row?.original?.email}`} className="text_dec_none">
            {row?.original?.email}
          </a>
        </div>
      ),
    },
    {
      header: "Status",
      accessorKey: "user_status",
      cell: ({ row }) => (
        <div>
          <span className={`_badge ${row?.original?.user_status === "1" ? "active" : "inactive"}`}>
            {row?.original?.user_status === "1" ? "Active" : "Inactive"}
          </span>
        </div>
      ),
    },
    {
      header: "Role",
      accessorKey: "is_admin",
      cell: ({ row }) => (
        <div id={row?.original?.id}>
          <span className="role capitalize">{row?.original?.role_name}</span>
        </div>
      ),
    },
    {
      header: "Department",
      accessorKey: "department",
      cell: ({ row }) => (
        <div id={row?.original?.id}>
          <span className="role capitalize">
            {row?.original?.department ?? notAvailable}
          </span>
        </div>
      ),
    },
    {
      header: "Space Used",
      accessorKey: "total_usage",
      cell: ({ row }) => (
        <div id={row?.original?.id}>
          <span className="role capitalize">
            {bytesToSize(row?.original?.total_usage)}
          </span>
        </div>
      ),
    },
    {
      header: "Wallet Balance ($)",
      accessorKey: "wallet_bal",
      cell: ({ row }) => (
        <div id={row?.original?.id}>
          <span className="role capitalize">
            {row?.original?.wallet_bal}
          </span>
        </div>
      ),
    },
    {
      header: "Last Logged In",
      accessorKey: "loggedin_time",
      cell: ({ row }) => (
        <div id={row?.original?.id}>
          {row?.original?.loggedin_time
            ? moment(row?.original?.loggedin_time).format(dateFormat)
            : notAvailable}
        </div>
      ),
    },
    superAdmin && {
      header: "Created On",
      accessorKey: "created_at",
      cell: ({ row }) => (
        <div id={row?.original?.id}>{moment(row?.original?.created_at).format(dateFormat)}</div>
      ),
    },
    {
      header: "Action",
      accessorKey: "action",
      enableSorting: false,
      cell: ({ row }) => {
        const allowOption =
          role === postpaidRoot || role === rootAdmin || role === superAdmin;

        const resendUserId = "resend_btn" + row?.id;
        const editUserId = "edit_btn" + row?.id;
        const moreButtonId = "more_btn" + row?.id;
        return (
          <div className="table-button flex flex-row gap-2">
            {!row?.original?.email_verify && (
              <Button
                size="sm"
                title="Resend email"
                className={` id${row?.original?.id}`}
                data-active={focusId === row?.original?.id ? true : false}
                id={resendUserId}
                aria-labelledby={`${resendUserId} ${row?.id}_id action`}
                type="button"
                autoFocus={focusId === row?.original?.id ? true : false}
                onClick={() => {
                  handleShow();
                  setUserId(row?.original?.id);
                }}
              >
                <MdOutgoingMail size={30} aria-hidden="true" focusable="false" />
              </Button>
            )}
            <Button
              size="sm"
              variant="outline"
              title="Edit user"
              className={` id${row?.original?.id}`}
              data-active={focusId === row?.original?.id ? true : false}
              id={editUserId}
              aria-labelledby={`${editUserId} ${row?.id}_id action`}
              type="button"
              autoFocus={focusId === row?.original?.id ? true : false}
              onClick={() => {
                if (row?.original?.user_role === postpaidRoot) {
                  navigate(
                    ROUTES.UPDATE_CUSTOM_USER.replace(/:id/g, row?.original?.id),
                    {
                      state: {
                        id: row?.original?.id,
                        mode: "edit",
                        user_role: row?.original?.user_role,
                      },
                    }
                  );
                } else {
                  navigate(ROUTES.UPDATE_USER.replace(/:id/g, row?.original?.id), {
                    state: { id: row?.original?.id, mode: "edit", user_role: row?.original?.user_role },
                  });
                }
              }}
            >
              <Edit3 aria-hidden="true" focusable="false" />
            </Button>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  id={moreButtonId}
                  size="sm"
                  variant="outline"
                  aria-labelledby={`${moreButtonId} ${row?.id}_id`}
                  aria-label="Open actions"
                  className="flex data-[state=open]:bg-muted items-center justify-center"
                >
                  <MoreHorizontal aria-hidden="true" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem
                  aria-label={
                    row?.original?.user_status === "1" ? "Inactive User" : "Active User"
                  }
                  id={row?.original?.id}
                  onClick={() => userType("user", row?.original?.id)}
                >
                  {row?.original?.user_status === "1" ? "Inactive User" : "Active User"}
                </DropdownMenuItem>
                {allowOption && (
                  <>
                    {row?.original?.user_role !== admin &&
                      row?.original?.user_role !== postpaidAdmin &&
                      row?.original?.user_role !== billingAdmin &&
                      row?.original?.user_role !== postpaidRoot &&
                      row?.original?.user_role !== rootAdmin &&
                      row?.original?.is_admin === "N" && (
                        <DropdownMenuItem
                          aria-label="make admin"
                          className="capitalize"
                          id={row?.original?.id}
                          onClick={() => userType("admin", row?.original?.id)}
                        >
                          make admin
                        </DropdownMenuItem>
                      )}

                    {row?.original?.user_role === postpaidAdmin &&
                      row?.original?.is_admin === "Y" && (
                        <DropdownMenuItem
                          aria-label="company user"
                          className="capitalize"
                          id={row?.original?.id}
                          onClick={() => userType("company", row?.original?.id)}
                        >
                          make company user
                        </DropdownMenuItem>
                      )}

                    {(row?.original?.user_role === admin ||
                      row?.original?.user_role === companyUser) && (
                        <DropdownMenuItem
                          aria-label="make billing admin"
                          className="capitalize"
                          id={row?.original?.id}
                          onClick={() => userType("billing_admin", row?.original?.id)}
                        >
                          make billing admin
                        </DropdownMenuItem>
                      )}
                  </>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )
      },
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      handleUserListTable();
    }, 500);
  }, []);

  return (
    <Fragment>
      {/* {!loading && status && ( */}
      {/* <Datatable
        tableHead={tableHead}
        data={user_list}
        renderCell={cellRendrer}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        setPageSize={setPageSize}
        pageSize={pageSize}
        type="user"
        search={true}
        pageSizing={true}
        paginationBar={true}
        tableDescription="User List"
        AddUserButton={
          role !== superAdmin ? (
            <div className="btn_group ms-4 me-2">
              <Button
                icon={<AddUserIcon />}
                title={"Add User"}
                className={"button--blue ms-auto"}
                onClick={() => navigate(ROUTES.ADD_NEW_USER)}
                autoFocus={true}
              />
            </div>
          ) : null
        }
        AddCustomUserButton={
          role === superAdmin ? (
            <div className="btn_group ms-4 me-2">
              <Button
                icon={<AddUserIcon />}
                title={"Add Postpaid Account"}
                className={"button--blue ms-auto"}
                onClick={() => navigate(ROUTES.ADD_NEW_CUSTOM_USER)}
                autoFocus={true}
              />
            </div>
          ) : null
        }
      /> */}

      <DataTablev2
        data={user_list}
        totalRecords={total_records}
        columns={columns}
        search={true}
        title={"User List"}
        searchableColumns={[
          {
            id: "name",
            title: "User List",
          }, {
            id: "email",
            title: "Email",
          }
        ]}
        addUserButton={
          role !== superAdmin ? (
            <div className="btn_group ms-4 me-2">
              <Button
                icon={<AddUserIcon />}
                title={"Add User"}
                className={"button--blue ms-auto"}
                onClick={() => navigate(ROUTES.ADD_NEW_USER)}
                autoFocus={true}
              >
                <AddUserIcon className="mr-2" /> Add User
              </Button>
            </div>
          ) : null
        }
        addCustomUserButton={
          role === superAdmin ? (
            <div className="btn_group ms-4 me-2">
              <Button
                size="lg"
                title={"Add Postpaid Account"}
                className={"button--blue ms-auto"}
                onClick={() => navigate(ROUTES.ADD_NEW_CUSTOM_USER)}
                autoFocus={true}
              >
                <AddUserIcon className="mr-2" /> Add Postpaid Account
              </Button>
            </div>
          ) : null
        }
      />

      {/* )}
      {!loading && !status && (
        <div className="no-record-main">
          <div className="no-record">{noRecordFound}</div>
        </div>
      )} */}
      <ConfirmPopUp
        // Icon={Trash3}
        heading={"confirmation"}
        confirmMsg={message.resendMailPopUp}
        show={show}
        handleClose={handleClose}
        onConfirmHandler={onConfirmHandler}
      />



      {/* <Dialog
        modal
        defaultOpen={false}
        open={show}
        onOpenChange={handleClose}
      >
        <DialogContent>

        <DialogHeader>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogDescription>
            {message.resendMailPopUp}
          </DialogDescription>

        </DialogHeader>
        <DialogFooter>
          <Button
            className="btn btn-primary btn-lg"
            onClick={onConfirmHandler}
          >
            Confirm
          </Button>
          <Button
            className="btn btn-secondary btn-lg"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogFooter>
        </DialogContent>

      </Dialog> */}

    </Fragment>
  );
};

export default DummyTable;
