import React, { useCallback, useState } from "react";
import moment from "moment";
import DataTablesComp from "components/dataTable";
import { ORDER_STATUS_CLASS } from "constants/job_status";
import { currencyFormatter, dateFormat, noRecordFound } from "util/helpers";
import DataTablev2 from "components/dataTablev2";

const InvoiceDetailsTable = ({ order_details, success, loading }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

    //This function handle datatable row and columns.
  const cellRendrer = useCallback(
    (cell, row, column, index) => {
      if (column === "id") {
        return (
          <>
            <a href="#" className="download-link">
              {"PORD-" + row?.id}
            </a>
          </>
        );
      }
      if (column === "order_generate_date") {
        return (
          <div>
            <span className="role">
              {moment(row.order_generate_date).format(dateFormat)}
            </span>
          </div>
        );
      }
      if (column === "order_total_pages") {
        return <div>{row.order_total_pages}</div>;
      }
      if (column === "label_text") {
        return (
          <div>
            <span className={`_badge ${ORDER_STATUS_CLASS[row?.label_value]}`}>
              {row?.label_text}
            </span>
          </div>
        );
      }
      if (column === "order_payable_amount") {
        return (
          <div>
            <span>{currencyFormatter(row?.order_payable_amount)}</span>
          </div>
        );
      }

      return row[column];
    },
    [order_details]
  );

  //This array is work for show table headers dynamicly in data table.
  const tableHead = [
    { title: "Order No.", key: "id", sorting: true },
    { title: "Date", key: "order_generate_date", sorting: true },
    { title: "Total Pages", key: "order_total_pages", sorting: true },
    { title: "Status", key: "label_text", sorting: true },
    { title: "total", key: "order_payable_amount", sorting: false },
  ];


  const columns = [
    {
      id: "id",
      accessorKey: "id",
      header: "Order No.",
      cell: ({ row }) => (
        <div>
          <span className="text_dec_none">{"PORD-" + row?.original?.id}</span>
        </div>
      ),
    },
    {
      id: "order_generate_date",
      accessorKey: "order_generate_date",
      header: "Date",
      cell: ({ row }) => (
        <div>
          <span className="text_dec_none">
            {moment(row?.original?.order_generate_date).format(dateFormat)}
          </span>
        </div>
      ),
    },
    {
      id: "order_total_pages",
      accessorKey: "order_total_pages",
      header: "Total Pages",
      cell: ({ row }) => (
        <div>
          <span className="text_dec_none">{row?.original?.order_total_pages}</span>
        </div>
      ),
    },
    {
      id: "label_text",
      accessorKey: "label_text",
      header: "Status",
      cell: ({ row }) => (
        <div>
          <span className={`_badge ${ORDER_STATUS_CLASS[row?.original?.label_value]}`}>
            {row?.original?.label_text}
          </span>
        </div>
      ),
    },
    {
      id: "order_payable_amount",
      accessorKey: "order_payable_amount",
      header: "Total",
      cell: ({ row }) => (
        <div>
          <span className="text_dec_none">
            {currencyFormatter(row?.original?.order_payable_amount)}
          </span>
        </div>
      ),
    },
  ];

  return (
    <div className="dt-responsive bg-white rounded-lg shadow-sm border">
      {/* {success && (
        <div> */}
          {/* {!loading && success && ( */}
            {/* <DataTablesComp
              tableHead={tableHead}
              data={order_details}
              renderCell={cellRendrer}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              setPageSize={setPageSize}
              pageSize={pageSize}
              type="order-details"
              search={false}
              pageSizing={true}
              paginationBar={true}
              tableDescription="Order Details List"
            /> */}

          <DataTablev2
            columns={columns}
            totalRecords={order_details?.length}
            search={false}
            title={"Invoice Information"}
            data={order_details ? order_details : []}
            tablePagination={true}
           />

          {/* )} */}
        {/* </div>
      )} */}
      {/* {!loading && !success && order_details?.message && (
        <div className="no-record-main">
          <div className="no-record">{noRecordFound}</div>
        </div>
      )} */}
    </div>
  );
};
export default InvoiceDetailsTable;
