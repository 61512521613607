import { REQUEST_APPROVALS_TITLE } from "constants/title";
import React from "react";
import ApprovalTable from "./ApprovalTable";

const Approval = () => {
  //Tab title
  document.title = REQUEST_APPROVALS_TITLE;

  return (
    <div className="mainWrapper clientUser">
      <div className="mainTitleWrapper ">
        <div className="items-center">
          <div sm={6} md={6}>
            {/* <h2 className="mainTitle mb-0" id="table_info">
              Approval
            </h2> */}
          </div>
        </div>
      </div>
      <div className="main-content">
        <ApprovalTable />
      </div>
    </div>
  );
};

export default Approval;
