import {
  BillingIcon,
  CategoryIcon,
  HolidayIcon,
  OrderIcon,
  UploadIcon,
  UsersIcon,
  Workload,
} from "assets/images";
import { ROUTES } from "routes/constant";
import { rolesForApproval } from "util/helpers";
import {
  allRoles,
  primaryRoles,
  secondaryRoles,
  superAdminRoles,
} from "util/helpers";

const navList = [
  {
    id: 1,
    type: "dashboard",
    label: "Dashboard",
    to: ROUTES.DASHBOARD,
    activeClass: "active",
    replace: true,
    Icon: CategoryIcon,
    activeFor: [ROUTES.DASHBOARD],
    roles: allRoles,
  },
  {
    id: 2,
    type: "upload",
    label: "Upload",
    to: ROUTES.UPDATE,
    activeClass: "active",
    replace: true,
    Icon: UploadIcon,
    activeFor: [ROUTES.UPDATE],
    roles: allRoles,
  },
  {
    id: 3,
    type: "manage-holiday",
    label: "Manage-Holidays",
    to: ROUTES.MANAGE_HOLIDAYS,
    activeClass: "active",
    replace: true,
    Icon: HolidayIcon,
    activeFor: [ROUTES.MANAGE_HOLIDAYS],
    roles: superAdminRoles,
  },
  {
    id: 4,
    type: "order-management",
    label: "Order",
    to: ROUTES.ORDER_MANAGEMENT,
    activeClass: "active",
    replace: true,
    Icon: OrderIcon,
    activeFor: [ROUTES.ORDER_MANAGEMENT, ROUTES.ORDER_DETAILS_BY_ID],
    roles: allRoles,
  },
  {
    id: 5,
    type: "clients-order-management",
    label: "Postpaid Orders",
    to: ROUTES.CLIENT_ORDER_MANAGEMENT,
    activeClass: "active",
    replace: true,
    Icon: OrderIcon,
    activeFor: [ROUTES.CLIENT_ORDER_MANAGEMENT, ROUTES.CLIENT_INVOICE_DETAILS],
    roles: superAdminRoles,
  },
  {
    id: 6,
    type: "manage-users",
    label: "Manage Users",
    to: ROUTES.MANAGE_USERS,
    activeClass: "active",
    replace: true,
    Icon: UsersIcon,
    activeFor: [
      ROUTES.ADD_NEW_USER,
      ROUTES.UPDATE_USER,
      ROUTES.ADD_NEW_CUSTOM_USER,
      ROUTES.MANAGE_USERS,
      ROUTES.UPDATE_CUSTOM_USER,
    ],
    roles: secondaryRoles,
  },
  {
    id: 7,
    type: "billings",
    label: "Billing",
    to: ROUTES.BILLINGS,
    activeClass: "active",
    replace: true,
    Icon: BillingIcon,
    activeFor: [ROUTES.BILLINGS],
    roles: primaryRoles,
  },
  {
    id: 8,
    type: "workload",
    label: "Workload",
    to: ROUTES.WORKLOAD,
    activeClass: "active",
    replace: true,
    Icon: Workload,
    activeFor: [ROUTES.WORKLOAD, ROUTES.DOCUMENT_DETAILS],
    roles: superAdminRoles,
  },
  {
    id: 9,
    type: "client-users",
    label: "Client Users",
    to: ROUTES.CLIENT_USERS,
    activeClass: "active",
    replace: true,
    Icon: UsersIcon,
    activeFor: [ROUTES.CLIENT_USERS, ROUTES.USERS_FILE_DETAILS],
    roles: superAdminRoles,
  },
  {
    id: 10,
    type: "requests",
    label: "Requests",
    to: ROUTES.APPROVAL_LIST,
    activeClass: "active",
    replace: true,
    Icon: UsersIcon,
    activeFor: [ROUTES.APPROVAL_LIST],
    roles: rolesForApproval,
  },
];

export { navList };
