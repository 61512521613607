import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchWorkloadListing } from "redux/asyncApi/userApi";
import _ from "lodash";
import Datatable from "components/dataTable";
import moment from "moment";
import "./workload.scss";
import {
  dateFormat,
  getFirstAndLast,
  // noRecordFound,
  notAvailable,
} from "util/helpers";
import { ORDER_STATUS_CLASS } from "constants/job_status";
import { ROUTES } from "routes/constant";
import DataTablev2 from "components/dataTablev2";

const DummyTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    loading,
    userAuth: { token },
  } = useSelector((state) => state.app) || {};
  const { workload_listing } = useSelector((state) => state.user) || {};
  const { files, status, total_records } = workload_listing;
  // const [currentPage, setCurrentPage] = useState(1);
  // const [pageSize, setPageSize] = useState(10);

  const location = useLocation();

  useEffect(() => {
    location.search && dispatch(fetchWorkloadListing({ token, url: location.search }));
  }, [dispatch, token, location.search]);

  // const tableHead = [
  //   {
  //     key: "name",
  //     title: "USER LIST",
  //     sorting: true,
  //     searching: true,
  //     width: "20%",
  //   },
  //   {
  //     key: "order_id",
  //     title: "ID",
  //     sorting: true,
  //     searching: false,
  //     width: "15%",
  //   },
  //   {
  //     key: "email",
  //     title: "EMAIL",
  //     sorting: true,
  //     searching: false,
  //     width: "20%",
  //   },
  //   {
  //     key: "original_file_name",
  //     title: "DOCUMENT NAME",
  //     sorting: true,
  //     searching: false,
  //     width: "20%",
  //   },
  //   {
  //     key: "file_page_count",
  //     title: "PAGES",
  //     sorting: true,
  //     searching: false,
  //     width: "5%",
  //   },
  //   {
  //     key: "file_deliver_date",
  //     title: "DELIVERY DATE",
  //     sorting: true,
  //     searching: true,
  //     width: "10%",
  //   },
  //   {
  //     key: "label_text",
  //     title: "STATUS",
  //     sorting: true,
  //     searching: false,
  //     width: "10%",
  //   },
  // ];

  // const cellRendrer = useCallback((cell, row, column, index) => {
  //   if (column === "name") {
  //     return (
  //       <div className="user-group">
  //         {row?.image === null ? (
  //           <span className="table_picture uppercase ">
  //             {row?.first_name && row?.last_name
  //               ? getFirstAndLast(row?.first_name, row?.last_name)
  //               : ""}
  //           </span>
  //         ) : (
  //           <img
  //             src={row?.image}
  //             className="img-fluid table_picture "
  //             alt={""}
  //           />
  //         )}
  //         <span className="full-name ms-2 capitalize">{`${row?.first_name} ${row?.last_name}`}</span>
  //       </div>
  //     );
  //   }

  //   if (column === "order_id") {
  //     return (
  //       <div id={row.id}>
  //         <div
  //           className="flex items-center"
  //           onClick={() =>
  //             navigate(ROUTES.DOCUMENT_DETAILS.replace(/:id/g, row.order_id), {
  //               state: {
  //                 id: row?.id,
  //                 user_id: row?.user_id,
  //                 order_id: row?.order_id,
  //               },
  //             })
  //           }
  //         >
  //           <a href={`#`} className="text_dec_none">
  //             {row?.order_invoice_number ?? notAvailable}
  //           </a>
  //         </div>
  //       </div>
  //     );
  //   }

  //   if (column === "email") {
  //     return (
  //       <div id={row.id} className="flex items-center">
  //         <a href={`mailto:${row?.email}`} className="text_dec_none">
  //           {row?.email}
  //         </a>
  //       </div>
  //     );
  //   }
  //   if (column === "original_file_name") {
  //     return (
  //       <div>
  //         <span>{row?.original_file_name}</span>
  //       </div>
  //     );
  //   }
  //   if (column === "file_page_count") {
  //     return (
  //       <div id={row.id}>
  //         <span className="role capitalize">{row?.file_page_count}</span>
  //       </div>
  //     );
  //   }
  //   if (column === "file_deliver_date") {
  //     return (
  //       <div id={row.id}>
  //         {row?.file_deliver_date
  //           ? moment(row.file_deliver_date).format(dateFormat)
  //           : "N/A"}
  //       </div>
  //     );
  //   }
  //   if (column === "label_text") {
  //     return (
  //       <span className={`_badge ${ORDER_STATUS_CLASS[row?.label_value]}`}>
  //         {row?.label_text}
  //       </span>
  //     );
  //   }
  //   return row[column];
  // }, []);

  const columns = [
    {
      header: "User List",
      accessorKey: "name",
      cell: ({ row }) => (
        <div className="user-group">
          {row?.original?.image === null ? (
            <span className="table_picture uppercase ">
              {row?.original?.first_name && row?.original?.last_name
                ? getFirstAndLast(row?.original?.first_name, row?.original?.last_name)
                : ""}
            </span>
          ) : (
            <img
              src={row?.original?.image}
              className="img-fluid table_picture "
              alt={""}
            />
          )}
          <span className="full-name ms-2 capitalize font-bold">{`${row?.original?.first_name} ${row?.original?.last_name}`}</span>
        </div>
      ),
    },
    {
      header: "Order ID",
      accessorKey: "order_id",
      cell: ({ row }) => (
        <div id={row.id}>
          <div
            className="flex items-center"
            onClick={() =>
              navigate(ROUTES.DOCUMENT_DETAILS.replace(/:id/g, row?.original?.order_id), {
                state: {
                  id: row?.original?.id,
                  user_id: row?.original?.user_id,
                  order_id: row?.original?.order_id,
                },
              })
            }
          >
            <a href={`#`} className="text_dec_none download-link font-bold">
              {"PORD-"+row?.original?.order_id ?? notAvailable}
            </a>
          </div>
        </div>
      ),
    },
    {
      header: "Email",
      accessorKey: "email",
      cell: ({ row }) => (
        <div id={row.id} className="flex items-center whitespace-break-spaces">
          <a href={`mailto:${row?.original?.email}`} className="text_dec_none break-words break-all">
            {row?.original?.email}
          </a>
        </div>
      ),
    },
    {
      header: "Document Name",
      accessorKey: "original_file_name",
      cell: ({ row }) => (
        //wrap file name in span to prevent overflow
        <div id={row.id} className="flex-wrap">
          <div id={row.id} className="whitespace-break-spaces">
            {row?.original?.original_file_name.length > 20 ? (
              <span className="break-words break-all font-bold" title={row?.original?.original_file_name}>{`${row?.original?.original_file_name}`}</span>
            ) : (
              <span className="font-bold">{row?.original?.original_file_name}</span>
            )}
          </div>
        </div>
      ),
    },
    {
      header: "Pages",
      accessorKey: "file_page_count",
      size: 30,
      cell: ({ row }) => (
        <div id={row.id}>
          <span className="role capitalize">{row?.original?.file_page_count}</span>
        </div>
      ),
    },
    {
      header: "Delivery Date",
      accessorKey: "file_deliver_date",
      cell: ({ row }) => (
        <div id={row.id}>
          {row?.original?.file_deliver_date
            ? moment(row.original.file_deliver_date).format(dateFormat)
            : "N/A"}
        </div>
      ),
    },
    {
      header: "Status",
      accessorKey: "label_text",
      cell: ({ row }) => (
        <span className={`_badge ${ORDER_STATUS_CLASS[row?.original?.label_value]}`} key={row.id}>
          {row?.original?.label_text}
        </span>
      ),
    },
  ];

  return (
    <Fragment>
      {/* {!loading && status && ( */}
      {/* <Datatable
        tableHead={tableHead}
        data={files}
        renderCell={cellRendrer}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        setPageSize={setPageSize}
        pageSize={pageSize}
        type="workload"
        search={true}
        pageSizing={true}
        paginationBar={true}
        tableDescription="User List"
      /> */}

      <DataTablev2
        data={files}
        totalRecords={total_records}
        columns={columns}
        search={true}
        title={"Workload"}
        searchableColumns={[
          {
            id: "name",
            title: "User List",
          }, {
            id: "file_deliver_date",
            title: "Delivery Date",
          }
        ]}
      />

      {/* )}
      {!loading && !status && (
        <div className="no-record-main">
          <div className="no-record">{noRecordFound}</div>
        </div>
      )} */}
    </Fragment>
  );
};

export default DummyTable;
