import React from 'react';
import { currencyFormatter } from 'util/helpers';
import DataTablev2 from 'components/dataTablev2';
import { OPTIONAL_SERVICES_COST_PER_PAGE } from 'constants/additionalServices';

const OptionalServicesTable = ({optionalServicesData}) => {
    const {acc_extend_alt, acc_tabel_summary, total_no_pages} = optionalServicesData

    const tableData = []
    if(acc_extend_alt > 0) {
        tableData.push({service: 'Extented Alt', total_no_pages , costPerPage: OPTIONAL_SERVICES_COST_PER_PAGE,  totalCost: acc_extend_alt })
    }
    if(acc_tabel_summary > 0) {
        tableData.push({service: 'Table Summary', total_no_pages, costPerPage: OPTIONAL_SERVICES_COST_PER_PAGE, totalCost: acc_tabel_summary})
    }

    const columns = [
        {
            id: "service",
            accessoryKey: "service",
            header: "Service Selected",
            cell: ({ row }) => {
              return (
                <div id={row?.original?.id} className="file_name_head">
                  {row?.original?.service}
                </div>
              );
            },
        },
        {
            id: "total_no_pages",
            accessoryKey: "total_no_pages",
            header: "Number of Pages",
            cell: ({ row }) => {
              return (
                <div id={row?.original?.id} className="file_name_head">
                  {row?.original?.total_no_pages}
                </div>
              );
            },
        },
        {
            id: "costPerPage",
            accessoryKey: "costPerPage",
            header: "Cost per Page",
            cell: ({ row }) => {
              return (
                <div id={row?.original?.id} className="file_name_head">
                  {currencyFormatter(row?.original?.costPerPage)}
                </div>
              );
            },
        },
        {
            id: "totalCost",
            accessoryKey: "totalCost",
            header: "Total Cost",
            cell: ({ row }) => {
              return (
                <div id={row?.original?.id} className="file_name_head">
                  {currencyFormatter(row?.original?.totalCost)}
                </div>
              );
            },
        }
    ]

    return (
        <>
        {tableData.length > 0 && (
                <DataTablev2
                data={tableData}
                totalRecords={tableData.length}
                columns={columns}
                search={false}
                title={"Additional Services"}
                tablePagination={false}
            />
        )}            
        </>
    )
}

export default OptionalServicesTable;